import { createTheme } from "@material-ui/core/styles";
//import grey from '@material-ui/core/colors/grey';
//import green from '@material-ui/core/colors/green';
import red from "@material-ui/core/colors/red";

export const primaryColour = "#005b00";
export const secondaryColour = "#013334";
export const lightTextColour = "#ffffff";

// create blank theme to expose breakpoints,
const theme = createTheme({
	palette: {
		primary: {
			//main: green[900],
			main: primaryColour
		},
		secondary: {
			//main: green[900],
			main: secondaryColour
		},
		error: red,
		contrastThreshold: 3,
		tonalOffset: 0.2
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Arial",
			"sans-serif"
		].join(",")
	}
});

// then overwrite theme and we can use breakpoints
// https://stackoverflow.com/a/56320113
export const MyTheme = {
	...theme,
	overrides: {
		MuiButton: {
			// override the styles of all instances of this component
			root: {
				// Name of the rule
				//color: 'white', // Some CSS
			}
		},
		MuiFormControl: {
			root: {
				width: "100%" //background: 'red',
			}
		},
		MuiCard: {
			root: {
				width: "100%",
				marginBottom: "20px"
			}
		},
		MuiCardContent: {
			root: {
				width: "100%" //background: 'red',
			}
		},
		MuiCardActionArea: {
			root: {
				width: "100%" //background: 'red',
			}
		},
		MuiListItemIcon: {
			root: {
				marginLeft: "10px"
			}
		},
		RaLabeled: {
			root: {
				width: "100%" //background: 'red',
			}
		},
		RaSingleFieldList: {
			root: {
				width: "100%" //background: 'red',
			},
			link: {
				display: "block",
				[theme.breakpoints.down("sm")]: {
					width: "100%"
				}
			}
		}
	}
};

export default MyTheme;
