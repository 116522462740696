// identification and encryption
import { fingerprint, cid, genkey } from "./security";

// token storage handling
import Token from "./token";

export default Config => {
	const { apikey } = Config;
	const authBase =
		(Config.API_BASE ||
			"https://" + Config.domain + (Config.path || "/x/api")) + "/auth";
	const authReq = (method, path, data) =>
		new Request(
			authBase + "/" + path, //'https://'+Config.domain+'/x/api/auth/login',
			{
				method: method, //'POST',
				body: JSON.stringify(data),
				headers: new Headers({
					"Content-Type": "application/json",
					apikey,
					fingerprint,
					cid
				})
			}
		);
	const authGet = (path, data) => fetch(authReq("GET", path, data));
	const authPut = (path, data) => fetch(authReq("PUT", path, data));
	const authPost = (path, data) => fetch(authReq("POST", path, data));
	const authPatch = (path, data) => fetch(authReq("PATCH", path, data));
	const authDelete = (path, data) => fetch(authReq("DELETE", path, data));
	const authUpdate = (path, data) => fetch(authReq("UPDATE", path, data));

	return {
		GET: authGet,
		PUT: authPut,
		POST: authPost,
		PATCH: authPatch,
		DELETE: authDelete,
		UPDATE: authUpdate,

		// called when the user attempts to log in
		login: ({ username, password }) => {
			const key = genkey(password);
			console.log("authProvider login", { Config, username, key });
			//localStorage.setItem('auth', username);

			return new Promise((resolve, reject) => {
				authPost("login", { username, key }) //fetch(request)
					.then(response => {
						if (response.status < 200 || response.status >= 300) {
							throw new Error(response.statusText);
						}
						return response.json();
					})
					.then(data => {
						console.log("authProvider login response", data);
						const token = data && data.token;
						if (token) {
							console.log("authProvider Login Token.set(token);", { token });
							Token.set(token); //localStorage.setItem('token', token);
							console.log("authProvider Login resolve", { data, token });
							resolve(data);
						} else {
							//throw "urgh";//data.error;
							console.log("authProvider Login reject", { data, token });
							reject(data.error);
						}
					})
					.catch(err => {
						reject(err);
					});
			});
		},
		// called when the user clicks on the logout button
		logout: () => {
			Token.clear(); //localStorage.removeItem('token');
			return Promise.resolve();
		},
		// called when the API returns an error
		checkError: ({ status }) => {
			if (status === 401 || status === 403) {
				Token.clear(); //localStorage.removeItem('token');
				return Promise.reject();
			}
			return Promise.resolve();
		},
		// called when the user navigates to a new location, to check for authentication
		checkAuth: () => {
			//#TODO: replave with getJwtToken()
			return Token.get().u //localStorage.getItem('token')
				? Promise.resolve()
				: Promise.reject();
		},
		// called when the user navigates to a new location, to check for permissions / roles
		getPermissions: () => Promise.resolve(),

		// called when user wants an email to reset their password
		forgotPassword: ({ username }) => {
			console.log("authProvider forgotPassword", { Config, username });
			return new Promise((resolve, reject) => {
				authPost("forgot", { email: username })
					.then(response => {
						if (response.status < 200 || response.status >= 300) {
							throw new Error(response.statusText);
						}
						return response.json();
					})
					.then(data => {
						if (data && data.status === "y") {
							resolve(data);
						} else {
							reject(data && data.error);
						}
					})
					.catch(err => {
						reject(err);
					});
			});
		},

		// called when user has clicked the email link and wants to reset their password
		resetPassword: ({ crypto, password }) => {
			const key = genkey(password);
			console.log("authProvider resetPassword", { Config, crypto, password });
			return new Promise((resolve, reject) => {
				authPost("reset", { crypto, password: key, password2: key })
					.then(response => {
						if (response.status < 200 || response.status >= 300) {
							throw new Error(response.statusText);
						}
						return response.json();
					})
					.then(data => {
						if (data && data.status == "y") {
							resolve(data);
						} else {
							reject(data && data.error);
						}
					})
					.catch(err => {
						reject(err);
					});
			});
		},

		// called when user has clicked the email link and wants to reset their password
		register: payload => {
			// encrypt password if it isn't already
			payload.password = genkey(payload.password);
			console.log("authProvider register", { Config, payload });
			return new Promise((resolve, reject) => {
				authPost("register", payload)
					.then(response => {
						if (response.status < 200 || response.status >= 300) {
							throw new Error(response.statusText);
						}
						return response.json();
					})
					.then(data => {
						if (data && data.status == "y") {
							resolve(data);
						} else {
							reject(data && data.error);
						}
					})
					.catch(err => {
						reject(err);
					});
			});
		}
	};
};
