import CryptoJS from 'crypto-js';
import Fingerprint2 from 'fingerprintjs2';

// helper function - encrypts password to fynework's specification
export const genkey = pwd => {
	const md5 =
		pwd &&
		CryptoJS.MD5(pwd)
			.toString()
			.toUpperCase();
	const sha = md5 && CryptoJS.SHA256(md5).toString();
	const key = sha && 'key-' + sha;
	return key;
};

// will populate this value on load
export let fingerprint = null;

// will run once and grab value from local storage
export let cid = (function() {
	const sid = 'cid';
	var cid = window.localStorage && localStorage.getItem(sid);
	if (!cid) {
		function s4() {
			return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
		}
		cid =
			'{' +
			s4() +
			s4() +
			'-' +
			s4() +
			'-' +
			s4() +
			'-' +
			s4() +
			'-' +
			s4() +
			s4() +
			s4() +
			'}';
		window.localStorage && localStorage.setItem(sid, cid);
	}
	//console.log('fwxcid cid',{cid});
	return cid;
})();

// local implementation
export const fwxcid = function(callback) {
	return new Promise(resolve => {
		const success = function(result) {
			//console.log('fwxcid success',result);
			fingerprint = result.fingerprint;
			cid = result.cid;
			resolve(result);
			if (callback) callback(result);
		};

		const sid = 'fingerprint';
		const hash = function(s) {
			return s.split('').reduce(function(a, b) {
				a = (a << 5) - a + b.charCodeAt(0);
				return a & a;
			}, 0);
		};
		const oldprint = localStorage.getItem(sid) || '';
		const curprint = fingerprint || '';
		if (!!curprint && curprint === oldprint) {
			//console.log('fwxcid Fingerprint was ready',{fingerprint:curprint});
			success({ cid, fingerprint: curprint });
		} else {
			//console.log('fwxcid Fingerprint fresh calc',{cid});
			Fingerprint2.getV18({ extendedFontList: true }, function(
				fingerprint,
				components
			) {
				if (oldprint === fingerprint) {
					//console.log('fwxcid Fingerprint unchanged',{fingerprint,components});
					success({ cid, fingerprint });
				} else {
					//console.log('fwxcid Fingerprint changed', {oldprint,newprint:fingerprint});

					var dump = {};
					components.map(x => (dump[x.key] = x.value));
					dump.canvas = hash(dump.canvas);
					dump.webgl = hash(dump.webgl);
					dump.plugins = hash(JSON.stringify(dump.plugins));

					localStorage.setItem(sid, fingerprint);
					localStorage.setItem(sid + '.dump', JSON.stringify(dump));

					success({ cid, fingerprint, oldprint, dump, changed: true });
				}
			});
		}
	});
};

export const fwxcid_init = function() {
	fwxcid().then(result => {
		if (!result) return console.error('Failed to identify device');
		console.log('Identified device', result);
		//if(!!result.changed){
		//    // https://stackoverflow.com/questions/24468459
		//    var xhr = new XMLHttpRequest();
		//    var url = '/x/api/fingerprint';
		//    xhr.open("POST", url, true);
		//    xhr.setRequestHeader("Content-Type", "application/json");
		//    //xhr.onreadystatechange = function () {};
		//    xhr.send(JSON.stringify(result));
		//};
		//if(callback) callback(result);
	});
};

window.requestIdleCallback
	? requestIdleCallback(fwxcid_init)
	: setTimeout(fwxcid_init, 500);
