import React from "react";
import { Loading } from "react-admin";

export const MyLoading = Loading;

// custom....
// import ReactLoading from 'react-loading';
// export const MyLoading = ({
// 	type,
// 	color,
// 	error,
// 	pastDelay,
// 	timedOut,
// 	retry = ()=>console.warn("Retry handler not defined")
// }) => (
// 	!!error
// 	? <div>Error! <button onClick={ retry }>Retry</button></div>
// 	: (
// 		!!pastDelay
// 		? (
// 			!!timedOut
// 			? <ReactLoading type={type} color={color} height={'20%'} width={'20%'} />
// 			: <ReactLoading type={type} color={"danger"} height={'20%'} width={'20%'} />
// 		)
// 		: null
// 	)
// );

export default MyLoading;
