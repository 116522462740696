import Loadable from "react-loadable";
import Loading from "theme/MyLoading";

export const Page = (name, method) =>
	Loadable({
		loader: () =>
			import("views/pages/" + name).then(m => m[name + (method || "")]),
		loading: Loading,
		delay: 300 // 0.3 seconds
	});
