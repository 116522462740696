//import './localStorage';

// application path
export const pathname = window.document.location.pathname;

// storage prefix
export const prefix = pathname.match(/^\/d\//)
	? "D"
	: pathname.match(/^\/x\//)
	? "B"
	: "F";

// storage key
export const key = "token";

// native data functions
const du = x => window.decodeURIComponent(x);
const eu = x => window.encodeURIComponent(x);
const sg = x => window.localStorage.getItem(x);
const ss = (x, v) => window.localStorage.setItem(x, v);

// getJwtStore function
export const getJwtStore = w => {
	var A = w || prefix;
	var s = sg(A + key) || sg(key);
	//console.log({func:'getJwtStore',A,c,key,s });
	return s;
};

// setJwtStorage function
export const setJwtStore = (w, v) => {
	var A = w || prefix;
	console.log({ func: "setJwtStore", A, prefix, v });
	ss(A + key, v);
};

// getJwtCookie function
export const getJwtCookie = w => {
	var A = w || prefix,
		c = getCookieWithoutSignature,
		k = c(A + "v"),
		s = k && c(A + k + "jwt");
	//console.log({func:'getStateCookie',A,c,k,s });
	return s;
};

// getJwtString function
export const getJwtString = w => {
	var A = w || prefix,
		s = getJwtStore(A) || getJwtCookie(A);
	//console.log({func:'getJwtString',A,s });
	return s;
};

// getJwtState function
export const getJwtState = w => {
	var A = w || prefix,
		s = getJwtString(A),
		x = s && parseJwt(s);
	//console.log({func:'getJwtState',A,s,x });
	return x;
};

// jwt token parser without native browser atob function
// https://stackoverflow.com/questions/38552003/how-to-decode-jwt-token-in-javascript-without-using-a-library
export const parseJwt1 = token => {
	var base64Url = token.split(".")[1];
	var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
	var jsonPayload = du(
		atob(base64)
			.split("")
			.map(function(c) {
				return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join("")
	);
	//console.log({func:'parseJwt1',base64Url,base64,jsonPayload });
	return JSON.parse(jsonPayload);
};
// jwt token parser without native browser atob function
export const parseJwt2 = token => {
	try {
		return JSON.parse(atob(token.split(".")[1]));
	} catch (e) {
		return null;
	}
};
export const parseJwt = token => {
	//return parseJwt1(token);
	return parseJwt2(token);
};
//parseJwt(localStorage.getItem("token")).u

//#TODO: don't just parse, add basic validation
export const getString = () => getJwtString(prefix) || "";
export const getToken = () => getJwtState(prefix) || {};
export const setToken = v => setJwtStore(prefix, v);
export const clearToken = v => setToken("");

// export
export default {
	string: getString,
	json: getToken,
	get: getToken,
	set: setToken,
	clear: clearToken
};

// old cookie integration, fallback
export const getCookie = n => {
	var name = eu(n);
	console.log("getCookie", n, name);
	var value = "; " + document.cookie;
	var parts = value.split("; " + name + "=");
	if (parts.length === 2)
		return du(
			parts
				.pop()
				.split(";")
				.shift()
		);
};

export const getCookieWithoutSignature = n =>
	(getCookie(n) || "").replace(/@[^@]+$/, "");

// getStateCookie function
export const getStateCookie = w => {
	var z = du,
		A = w || prefix,
		//cs = document.cookie.split(/;\s*/gi),
		c = getCookieWithoutSignature, //(n) => cs.filter(function(c,i){ /*console.log('getStateCookie filter',i,c); */ return c && String(z(c)).match(RegExp('^'+n+'\=','gi')) }).map((c)=>{ return z(c.split('=')[1]).replace(/\@[^@]+$/,'') })[0],
		k = c(A + "v"),
		s = k && c(A + k);
	/*
  console.log({func:'getStateCookie',A,cs,c,k,s });
  */
	return s;
};

// getState function
export const getState = w => {
	var z = du,
		A = w || prefix,
		o = a => {
			var s = {};
			for (var i = 0; i < a.length; i++) {
				var x = a[i].split("=");
				s[x[0]] = z(x[1]).replace("+", " ");
			}
			return s;
		},
		s = getStateCookie(A),
		a = s && s.split("&"),
		x = a && o(a);
	//console.log('getState', {func:'getState',A,o,s,a,x});
	return x;
};
