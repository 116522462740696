import countryList from "react-select-country-list";

export const DefaultCountry = null; // countryList().getData().filter(x=>x.value=='GB')[0];
//console.log('DefaultCountry', DefaultCountry, {list:countryList(),data:countryList().getData()});

export const SET_BOOKING = "SET_BOOKING";

export const initialState = {
	name: "",
	phone: "",
	email: "",
	email2: "",
	consent: [],
	sale: {
		trees: [{ code: "", size: "" }],
		reference: "",
		description: "",
		trees_only: true
	},
	shipping: {
		AdminOverride: false,
		name: "",
		email: "",
		phone: "",
		address1: "",
		address2: "",
		address3: "",
		postcode: "",
		county: "",
		city: "London",
		country: DefaultCountry
	},
	billing: {
		sameas: "same",
		name: "",
		email: "",
		phone: "",
		address1: "",
		address2: "",
		address3: "",
		postcode: "",
		county: "",
		city: "London",
		country: DefaultCountry
	},
	delivery: {
		date: "", //new Date(),
		option: null,
		optionChoice: null,
		time: null,
		timeChoice: null,
		parkingChoice: {},
		buildingChoice: {},
		levelChoice: {},
		entranceChoice: {}
	}
};

export default (state = initialState, action) => {
	const { type, payload } = action;

	if (type === SET_BOOKING) {
		return payload;
	}

	return initialState;
};
