// in src/userSagas.js
// https://marmelab.com/react-admin/Admin.html#customsagas
import { put, takeEvery } from "redux-saga/effects";
import { showNotification } from "react-admin";
export const USER_REGISTERED = "USER_REGISTERED";

export default function* userRegisteredSaga() {
	yield takeEvery(USER_REGISTERED, function*() {
		yield put(showNotification("User registered"));
	});
}
