export default {
	ra: {
		auth: {
			password: "Password",
			username: "Username",

			login_button: "Login",
			login_invalid: "Invalid username or password",
			login_forgot_link: "Forgot Password?",
			login_title: "Please Login",
			login_remember: "Remember login?",
			login_register_link: "Register now",
			login_success: "Login success",
			login_error: "Login error",

			register_button: "Register Now",
			register_invalid: "Invalid information",
			register_title: "Register Now",
			register_login_link: "Already have an account? Login here",
			register_success: "Registration confirmed!",
			register_error: "Registration error",

			reset_button: "Reset password",
			reset_invalid: "Invalid information",
			reset_title: "Reset Password",
			reset_success: "Password reset!",
			reset_error: "Could not reset password",

			book_invalid: "Invalid information",
			book_title: "Book Now",
			book_success: "Booking confirmed!",
			book_error: "Booking error"
		},
		stores: {
			home_button: "Book Now",
			home_invalid: "Invalid information",
			home_title: "Delivery from Stores",
			home_success: "Booking confirmed!",
			home_error: "Booking error",
			home_book_link: "Book Delivery now",

			book_button: "Book Now & Pay",
			book_button_error: "Please fill all the required details above",
			book_button_error_notification: "Please correct errors indicated",

			book_invalid: "Invalid information",
			book_title: "Book Store Delivery",
			book_success: "Booking confirmed!",
			book_error: "Booking error",

			book_which_store: "Please select store form the list below",
			book_change_store_button: "change Store",

			book_contact_information: "Your contact details",
			book_delivery_address: "Your delivery address",
			book_order_information: "Your order information",
			book_input_reference: "Please enter receipt number",
			book_sale_description:
				"Any other items purchased? ie.: stand or wooden block",
			book_input_instructions:
				"Are there any other instructions, directions for driver, etc?",

			book_tree_code_title: "Trees Purchased",
			book_tree_code_explanation:
				"Please enter the last 6 digits of the number displayed on your tree label",
			book_tree_code_error:
				"You must enter the code from your tree label",

			book_other_stuff_title: "Other Items Purchased",
			book_other_stuff_explanation:
				"Please state if you purchased a stand, wooden block or any other accessories",
			book_other_stuff_nothing_else: "No other items",

			book_delivery_options: "Delivery options",
			book_delivery_options_explanation:
				"Please choose a delivery option. Would you like our driver to simply deliver the tree(s), or would you also like them to install the tree(s) onto its stand, leaving it ready to bve decorated?",

			other_information: "Other Information",
			other_information_explanation:
				"Please provide the answers below so we can more efficiently plan your delivery",

			book_billing_address: "Billing Address",
			book_billing_address_explanation:
				"You must provide the correct billing address that matches the payment card. If the card is registered to a different address that is not your delivery address, please provide the exact billing address below. Your payment will be rejected if the billing address provided does not match the address in your bank's records",

			payment_details: "Payment Details",
			payment_instructions:
				"Please note you billing address must mmatch the card's registered address",

			booking_confirmed: "Booking Confirmed!",

			form_submit_error: "Could not submit form",

			book_manager_prompt_wrong_pin: "Wrong PIN, please try again",
			book_manager_prompt_intro:
				"Enter your management pin to disable postcode restrictions:",
			book_manager_prompt_cancel: "Cancel",
			book_manager_prompt_confirm: "Confirm",

			book_confirmation_prompt_above: "Starting Payment",
			book_confirmation_prompt_below: "Please Wait",

			book_working_prompt_above: "Booking Delivery",
			book_working_prompt_below: "Please Wait",

			payment_title: "Payment Details",
			payment_explanation:
				"Enter you payment details below to confirm your booking",

			delivery_date_not_available:
				"Sorry, that delivery date is not available any more, please choose another date",

			book_confirmation_screen_title: "Booking Confirmed",
			book_confirmation_screen_above:
				"You will have a confirmation email shortly.",
			book_confirmation_screen_below:
				"You can track your delivery and your driver contact details on our app"
		},
		error: {
			password_mismatch: "Passwords did not match",
			name_required: "Name required",
			email_required: "Email required",
			mobile_required: "Mobile required",
			program_required: "Program required"
		},
		menu: {
			title: "Menu"
		}
	},
	UNKNOWN_ERROR: "Unknown error"
};
