import { publish } from "lib/pubsub";

console.log("install prompt init");

// Show an element
const show = (window.show = function(elem) {
	if (elem.length) [].forEach.call(elem, show);
	if (elem && elem.style) elem.style.display = "block";
});

// Hide an element
const hide = (window.hide = function(elem) {
	if (elem.length) [].forEach.call(elem, hide);
	if (elem && elem.style) elem.style.display = "none";
});

// https://developers.google.com/web/fundamentals/app-install-banners
let deferredPrompt;
window.addEventListener("beforeinstallprompt", e => {
	console.log("install prompt captured");

	// Prevent Chrome 76 and later from showing the mini-infobar
	e.preventDefault();

	// Stash the event so it can be triggered later.
	window.deferredPrompt = deferredPrompt = e;

	// Update UI notify the user they can add to home screen
	//$('body').addClass('install-prompt-available');
});
window.addEventListener("appinstalled", evt => {
	console.log("install prompt success - app installed");

	// publish info
	publish({ action: "installed" });
	//TODO: pubsub integration
});

export const installPrompt = () => {
	console.log("install prompt invoked");
	if (!deferredPrompt)
		return console.warn(
			"install prompt was not captured by beforeinstallprompt event"
		);

	// turnd off
	console.warn("never prompt");
	return null;
	/*

	// Show the prompt
	deferredPrompt.prompt();
	// The prompt() method must be called with a user gesture

	// Wait for the user to respond to the prompt
	deferredPrompt.userChoice.then(choiceResult => {
		if (choiceResult.outcome === "accepted") {
			console.log("User accepted the A2HS prompt");
		} else {
			console.log("User dismissed the A2HS prompt");
		}
		deferredPrompt = null;
	});
	// return true if an attempt was possible
	return true;

	*/
};

// ask user to install
setTimeout(() => {
	const eleToShow = document.getElementsByClassName("install-prompt-available");
	const eleToHide = document.getElementsByClassName(
		"install-prompt-unavailable"
	);

	if (deferredPrompt) {
		// only one time!
		if (window.localStorage && localStorage.getItem("install-prompt") == "y") {
			return;
		}
		localStorage.setItem("install-prompt", "y");

		// show hide
		show(eleToShow); //.css.display = 'block';
		hide(eleToHide); //.css.display = 'none';

		try {
			// automatically prompt for install, but only once
			installPrompt(); //fwx.fe.install();
		} catch (e) {
			localStorage.setItem("install-prompt", "");
			console.log("install prompt failed", { e });
		}
	} else {
		// hide menu item, installl not available
		hide(eleToShow); //.css.display = 'hide';
		show(eleToHide); //.css.display = 'block';
	}
}, 5 * 1000);

window.fwx = window.fwx || {};
window.fwx.fe = window.fwx.fe || {};
window.fwx.fe.install = installPrompt;
