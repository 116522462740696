export const publish = function(payload) {
	// pub nub must be loaded
	const pubnub = window.pubnub;
	if (!pubnub) {
		console.warn("fwx.driver.publish", "pubnub not available");
		return;
	}

	// default channel for this app
	const channel = window.PUBSUB_CHANNEL || "drivers";

	// prepare message
	var message = Object.assign({}, payload);
	delete message.base64;
	delete message.base64UrlSwapID;
	delete message.image;
	delete message.signature;
	delete message.base64OfSignatureForAction;
	delete message.base64UrlSwapIDOfSignature;
	delete message.base64OfImageForAction;
	delete message.base64UrlSwapIDOfImage;
	//console.log('fwx.driver.publish','pubnub message', message);

	// literal payload
	if (payload.payload) {
		var literal = message.payload;
		console.log("fwx.driver.publish", "literal payload", literal);

		delete message.payload;
		message = Object.assign({}, message, literal);
		console.log("fwx.driver.publish", "literal message", message);
	} else {
		// get job scope data
		//var $scope = $('.job-status-scope:visible');
		//if(!$scope.length) $scope = $('body');
		var $scope = document.body;

		// populate with contextual data
		if ($scope) {
			var dataset = $scope.dataset;
			message = Object.assign(
				{},
				{
					driver: dataset.driver,
					helper: dataset.helper,
					vehicle: dataset.vehicle,
					navigator: dataset.navigator,
					assistant: dataset.assistant,
					invoice: dataset.invoice,
					job: dataset.jo,
					account: dataset.account,
					merchant: dataset.merchant,
					stockist: dataset.stockist,
					code: dataset.code,
					service: dataset.service,
					option: dataset.option
				},
				message
			);
			//console.log('fwx.driver.publish','pubnub message with context', message);
		} else {
			//console.log('fwx.driver.publish','pubnub message without context', message);
		}
	}

	//fwx.tracking.track()
	Promise.resolve()
		.then(tracking_data => {
			//console.log('fwx.driver.publish','pubnub location', tracking_data);

			var location_data =
				tracking_data &&
				tracking_data.data; /* && {
                "altitude": tracking_data.data.altitude,
                "accuracy": tracking_data.data.accuracy,
                "heading": tracking_data.data.heading,
                "speed": tracking_data.data.speed,
                "coords": tracking_data.data.coords,
            }*/
			message = Object.assign({}, location_data || {}, message);
			//keep message.accuracy; //: 1386
			//keep message.altitude; //: null
			delete message.altitudeAccuracy; //: null
			delete message.asof; //: "2019-12-05T07:24:52.629Z"
			//keep message.coords; //: "51.4413373,-0.17582009999999998"
			//keep message.heading; //: null
			delete message.lat; //: 51.4413373
			delete message.latitude; //: 51.4413373
			delete message.lng; //: -0.17582009999999998
			delete message.longitude; //: -0.17582009999999998
			//keep message.speed; //: null
			//console.log('fwx.driver.publish','pubnub message with location', message);

			// enrich payload with telemetry information
			if (window.Q_TELEMETRY) message["@telemetry"] = window.Q_TELEMETRY();

			pubnub
				.publish({
					channel: channel, //window.PUBSUB_CHANNEL || "drivers", //fwx.driver.channel() || "drivers",
					message: message
				})
				.then(result => {
					console.log("fwx.driver.publish", "pubnub result", result);
				})
				.catch(err => {
					console.warn("fwx.driver.publish", "pubnub error", err);
				});
		})
		.catch(err => {
			console.warn("fwx.driver.publish", "pubnub location error", err);

			message = Object.assign({}, { "tracking-failed": "y" }, message);
			//console.log('fwx.driver.publish','pubnub message without location', message);

			pubnub.publish({
				channel: channel, //window.PUBSUB_CHANNEL || "drivers", //fwx.driver.channel() || "drivers",
				message: message
			});
		});
};
