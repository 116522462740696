// in src/bookingSagas.js
// https://marmelab.com/react-admin/Admin.html#customsagas
import { put, takeEvery } from "redux-saga/effects";
import { showNotification } from "react-admin";
export const BOOKING_COMPLETE = "BOOKING_COMPLETE";

export default function* bookingCompleteSaga() {
	yield takeEvery(BOOKING_COMPLETE, function*() {
		yield put(showNotification("Booking complete"));
	});
}
