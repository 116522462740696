import React, { useMemo, useEffect } from "react";
//import Config from './lib/config'

console.log("FFS process.env before;", {
	CONTEXT: process.env.CONTEXT,
	NODE_ENV: process.env.NODE_ENV,
	env: process.env
});
require("dotenv").config();
console.log("FFS process.env after;", {
	CONTEXT: process.env.CONTEXT,
	NODE_ENV: process.env.NODE_ENV,
	env: process.env
});

//import "./App.scss";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";

//import { Admin, resolveBrowserLocale } from 'react-admin';

import "./lib/security";
import authProvider from "./lib/authProvider";
import dataProvider from "./lib/dataProvider";
import i18nProvider from "./lib/i18nProvider";
import * as async from "./lib/async";

// create our own store
// https://marmelab.com/react-admin/CustomApp.html
import { Provider } from "react-redux";
import createAdminStore from "./lib/createAdminStore";
import { createHashHistory } from "history";
import { ConnectedRouter } from "connected-react-router";
import { Switch, Route } from "react-router-dom";
import {
	//AuthContext,
	DataProviderContext,
	TranslationProvider,
	//Resource,
	Notification
} from "react-admin";
//import withContext from 'recompose/withContext'; // You should add recompose/withContext to your dependencies
//import AppBar from '@material-ui/core/AppBar';
//import Toolbar from '@material-ui/core/Toolbar';
//import Typography from '@material-ui/core/Typography';

//import InvoiceIcon from '@material-ui/icons/Receipt';
//import JobIcon from '@material-ui/icons/LocalShipping';
//import JobIcon from '@material-ui/icons/EventOutlined';
//import ItemIcon from '@material-ui/icons/ToysOutlined';
//import QuoteIcon from '@material-ui/icons/FormatQuoteOutlined';
//import InvoiceIcon from '@material-ui/icons/DescriptionOutlined';
//import AddressIcon from '@material-ui/icons/ContactsOutlined';
//import ProfileIcon from '@material-ui/icons/Person';

//import jsonServerProvider from 'ra-data-json-server';
//const dataProvider = jsonServerProvider('https://jsonplaceholder.typicode.com');

import "lib/install";

import { MyTheme } from "./theme/MyTheme";
//import { MyLayout } from './theme/MyLayout';
//import { MyLogoutButton } from './theme/MyLogout';

//import history from './lib/history';
// doesn't work in multi-tenant cross-domain apps

//import customRoutes from './routes';
import customReducers from "./reducers";
import customSagasArray from "./sagas";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const REACT_APP_STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLIC_KEY);

const Home = async.Page("Home");
const Book = async.Page("Book");

const localHistory = createHashHistory();

export const App = () => {
	// parse query here
	const query = useMemo(
		() =>
			// not ideal, cannot be stringified
			//new Proxy(new URLSearchParams(window.location.search), { get: (searchParams, prop) => searchParams.get(prop) })
			// ideal method, can also be stringified
			Object.fromEntries(new URLSearchParams(window.location.search).entries()),
		[]
	);
	const path = window.location.pathname;
	const staff = query?.staff || "";
	const isStaff = !!query.staff || path.match(/^\/(staff)/gi);

	//http://localhost:3000/client/forgot?crypto=26577.c8a9d36bd35903732a6a407ae05cce17e0e7246a820ef33d1cfcda618fa17b6e@20200802132821.90371870592604c9cbbaf49692810aaa0176d12dd467d0fa402a357be1580dbd&r=/checkout#/login
	const { hash, pathname, search } = window.location;
	const forgot = "/client/forgot";
	const home = "/";
	const book = "/book";
	useEffect(() => {
		console.log("start", { hash, forgot, pathname, search });
		//if(pathname!==home){
		//    window.location = home+'#'+book;
		//}
		//if(pathname===home && !hash){
		//    window.location.hash = book;
		//}

		var ohr = "";
		if (hash.match(/\b(barn)$/gi)) ohr = "/#/book/barn01";
		if (hash.match(/\b(batt)$/gi)) ohr = "/#/book/batt02";
		if (hash.match(/\b(bour)$/gi)) ohr = "/#/book/bour01";
		if (hash.match(/\b(brnt)$/gi)) ohr = "/#/book/brnt03";
		if (hash.match(/\b(broc)$/gi)) ohr = "/#/book/broc01";
		if (hash.match(/\b(brus)$/gi)) ohr = "/#/book/brus01";
		if (hash.match(/\b(bush)$/gi)) ohr = "/#/book/bush01";
		if (hash.match(/\b(chis)$/gi)) ohr = "/#/book/chis02";
		if (hash.match(/\b(clis)$/gi)) ohr = "/#/book/clis01";
		if (hash.match(/\b(crys)$/gi)) ohr = "/#/book/crys02";
		if (hash.match(/\b(dulw)$/gi)) ohr = "/#/book/dulw01";
		if (hash.match(/\b(ealb)$/gi)) ohr = "/#/book/ealb01";
		if (hash.match(/\b(ealc)$/gi)) ohr = "/#/book/ealc01";
		if (hash.match(/\b(eact)$/gi)) ohr = "/#/book/eact01";
		if (hash.match(/\b(finc)$/gi)) ohr = "/#/book/finc01";
		if (hash.match(/\b(fins)$/gi)) ohr = "/#/book/fins01";
		if (hash.match(/\b(forn)$/gi)) ohr = "/#/book/forn01";
		if (hash.match(/\b(fria)$/gi)) ohr = "/#/book/fria01";
		if (hash.match(/\b(fulh)$/gi)) ohr = "/#/book/fulh01";
		if (hash.match(/\b(gren)$/gi)) ohr = "/#/book/gren01";
		if (hash.match(/\b(hbur)$/gi)) ohr = "/#/book/hbur01";
		if (hash.match(/\b(kenw)$/gi)) ohr = "/#/book/kenw01";
		if (hash.match(/\b(king)$/gi)) ohr = "/#/book/king01";
		if (hash.match(/\b(lave)$/gi)) ohr = "/#/book/lave01";
		if (hash.match(/\b(leev)$/gi)) ohr = "/#/book/leev02";
		if (hash.match(/\b(maid)$/gi)) ohr = "/#/book/maid01";
		if (hash.match(/\b(nott)$/gi)) ohr = "/#/book/nott01";
		if (hash.match(/\b(milp)$/gi)) ohr = "/#/book/milp01";
		if (hash.match(/\b(putn)$/gi)) ohr = "/#/book/putn01";
		if (hash.match(/\b(rege)$/gi)) ohr = "/#/book/rege01";
		if (hash.match(/\b(rich)$/gi)) ohr = "/#/book/rich01";
		if (hash.match(/\b(rpop)$/gi)) ohr = "/#/book/rpop02";
		if (hash.match(/\b(rndw)$/gi)) ohr = "/#/book/rndw01";
		if (hash.match(/\b(shor)$/gi)) ohr = "/#/book/shor01";
		if (hash.match(/\b(stal)$/gi)) ohr = "/#/book/stal01";
		if (hash.match(/\b(hoxt)$/gi)) ohr = "/#/book/hoxt01";
		if (hash.match(/\b(stre)$/gi)) ohr = "/#/book/stre01";
		if (hash.match(/\b(whac)$/gi)) ohr = "/#/book/whac02";
		if (hash.match(/\b(upph)$/gi)) ohr = "/#/book/upph01";
		if (hash.match(/\b(vict)$/gi)) ohr = "/#/book/vict01";
		if (hash.match(/\b(wimb)$/gi)) ohr = "/#/book/wimb01";
		if (ohr) {
			console.log("start go: ohr", { ohr, hash, forgot, pathname, search });
			window.location = ohr;
			return;
		}

		var ocr = "";
		if (pathname.match(/\/barn\/?$/gi)) ocr = "/#/book/barn01";
		if (pathname.match(/\/batt\/?$/gi)) ocr = "/#/book/batt02";
		if (pathname.match(/\/bour\/?$/gi)) ocr = "/#/book/bour01";
		if (pathname.match(/\/brnt\/?$/gi)) ocr = "/#/book/brnt03";
		if (pathname.match(/\/broc\/?$/gi)) ocr = "/#/book/broc01";
		if (pathname.match(/\/brus\/?$/gi)) ocr = "/#/book/brus01";
		if (pathname.match(/\/bush\/?$/gi)) ocr = "/#/book/bush01";
		if (pathname.match(/\/chis\/?$/gi)) ocr = "/#/book/chis02";
		if (pathname.match(/\/clis\/?$/gi)) ocr = "/#/book/clis01";
		if (pathname.match(/\/crys\/?$/gi)) ocr = "/#/book/crys02";
		if (pathname.match(/\/dulw\/?$/gi)) ocr = "/#/book/dulw01";
		if (pathname.match(/\/ealb\/?$/gi)) ocr = "/#/book/ealb01";
		if (pathname.match(/\/ealc\/?$/gi)) ocr = "/#/book/ealc01";
		if (pathname.match(/\/eact\/?$/gi)) ocr = "/#/book/eact01";
		if (pathname.match(/\/finc\/?$/gi)) ocr = "/#/book/finc01";
		if (pathname.match(/\/fins\/?$/gi)) ocr = "/#/book/fins01";
		if (pathname.match(/\/forn\/?$/gi)) ocr = "/#/book/forn01";
		if (pathname.match(/\/fria\/?$/gi)) ocr = "/#/book/fria01";
		if (pathname.match(/\/fulh\/?$/gi)) ocr = "/#/book/fulh01";
		if (pathname.match(/\/gren\/?$/gi)) ocr = "/#/book/gren01";
		if (pathname.match(/\/hbur\/?$/gi)) ocr = "/#/book/hbur01";
		if (pathname.match(/\/kenw\/?$/gi)) ocr = "/#/book/kenw01";
		if (pathname.match(/\/king\/?$/gi)) ocr = "/#/book/king01";
		if (pathname.match(/\/lave\/?$/gi)) ocr = "/#/book/lave01";
		if (pathname.match(/\/leev\/?$/gi)) ocr = "/#/book/leev02";
		if (pathname.match(/\/maid\/?$/gi)) ocr = "/#/book/maid01";
		if (pathname.match(/\/nott\/?$/gi)) ocr = "/#/book/nott01";
		if (pathname.match(/\/milp\/?$/gi)) ocr = "/#/book/milp01";
		if (pathname.match(/\/putn\/?$/gi)) ocr = "/#/book/putn01";
		if (pathname.match(/\/rege\/?$/gi)) ocr = "/#/book/rege01";
		if (pathname.match(/\/rich\/?$/gi)) ocr = "/#/book/rich01";
		if (pathname.match(/\/rpop\/?$/gi)) ocr = "/#/book/rpop02";
		if (pathname.match(/\/rndw\/?$/gi)) ocr = "/#/book/rndw01";
		if (pathname.match(/\/shor\/?$/gi)) ocr = "/#/book/shor01";
		if (pathname.match(/\/stal\/?$/gi)) ocr = "/#/book/stal01";
		if (pathname.match(/\/hoxt\/?$/gi)) ocr = "/#/book/hoxt01";
		if (pathname.match(/\/stre\/?$/gi)) ocr = "/#/book/stre01";
		if (pathname.match(/\/whac\/?$/gi)) ocr = "/#/book/whac02";
		if (pathname.match(/\/upph\/?$/gi)) ocr = "/#/book/upph01";
		if (pathname.match(/\/vict\/?$/gi)) ocr = "/#/book/vict01";
		if (pathname.match(/\/wimb\/?$/gi)) ocr = "/#/book/wimb01";
		if (ocr) {
			console.log("start go: ocr", { ocr, hash, forgot, pathname, search });
			window.location = ocr;
			return;
		}
		if (query.store_code) {
			console.log("start go: ocr", { ocr, hash, forgot, pathname, search });
			window.location.hash = `#/book/${query.store_code}`;
			return;
		}
		if (pathname === "/book" || pathname === "/staff") {
			console.log("start go: url type B", { hash, forgot, pathname, search });
			window.location.hash = "#/book";
			return;
		}
		if (pathname.match(/^\/(\w{4})\/?$/) && pathname != "/book") {
			console.log("start go: url type 1", { hash, forgot, pathname, search });
			window.location = pathname.replace(/\/(\w{4})\/?$/gi, "/#/book/$101");
			return;
		}
		if (pathname.match(/^\/(\w{4})\d{2}\/?$/)) {
			console.log("start go: url type 2", { hash, forgot, pathname, search });
			window.location = pathname.replace(/\/(\w{4}\d{2})\/?$/gi, "/#/book/$1");
			return;
		}
		if (pathname === forgot) {
			console.log("start push", { hash, forgot, pathname, search });
			window.location.hash = forgot + search;
		}
	}, [pathname]);
	console.log("start go", { hash, forgot, pathname, search });
	if (pathname == forgot && hash != `#${pathname}${search}`) return "";

	return (
		<Elements stripe={stripePromise}>
			<ThemeProvider theme={MyTheme}>
				<CssBaseline />
				<Provider
					store={createAdminStore({
						authProvider,
						dataProvider,
						history: localHistory,
						customReducers,
						customSagasArray
					})}
				>
					<DataProviderContext.Provider value={dataProvider}>
						<TranslationProvider locale="en" i18nProvider={i18nProvider}>
							<React.Fragment>
								<ConnectedRouter history={localHistory}>
									<Switch>
										<Route exact path="/" component={Home} />
										<Route exact path="/book" component={Book} />
										<Route
											exact
											path="/book/:store"
											render={routeProps => (
												<Book
													basePath={routeProps.match.url}
													store={decodeURIComponent(
														routeProps.match.params.store
													)}
													{...routeProps}
												/>
											)}
										/>
									</Switch>
								</ConnectedRouter>
								<Notification />
							</React.Fragment>
						</TranslationProvider>
					</DataProviderContext.Provider>
				</Provider>
			</ThemeProvider>
		</Elements>
	);
};

export default App;
